import * as Cookies from "js-cookie";

/**
 * Information about secondary eTLD domain request
 */
interface SecondaryDomainRequest {
  readonly nonce: string;
  readonly secondaryDomain: string;
}

interface SessionStatus {
  readonly session_uuid: string;
  readonly client_cert_valid: boolean;
  readonly totp_enabled: boolean;
  readonly display_otp_enabled: boolean;
  readonly otp_enabled: boolean;
  readonly authenticated: boolean;
  readonly auth_prompt: string;
  readonly amr: string[];
  readonly require_2fa: boolean;
}

interface PageMetaTags {
  readonly my_security_key_settings_link: string;
  readonly secured_by_midway_partition_name: string;
  readonly secured_by_midway_wiki_link: string;
  readonly midway_partition: string;
  readonly compliance_validation_failed: string;
  readonly posture_error_message: string;
  readonly device_type: string;
  readonly aea_app_redirect: string;
  readonly posture_verification_failed: string;
}

function extractSessionStatus() {
  const sessionStatusJson = document.querySelector("meta[id='session-status']")?.getAttribute("content");
  return JSON.parse(sessionStatusJson ?? "{}") as SessionStatus;
}

function extractMcsBraveheartUserStatus() {
  const mcsBraveheartUser = document.querySelector("meta[id='mcs-braveheart-user-status']")?.getAttribute("content");
  return mcsBraveheartUser === "true";
}

export function getMetaTagsContent(): PageMetaTags {
  const sessionDetailsJson = document.querySelector("meta[id='ui-params']")?.getAttribute("content");
  return JSON.parse(sessionDetailsJson ?? "{}") as PageMetaTags;
}

const sessionStatus = extractSessionStatus();
const metaTagsDetails = getMetaTagsContent();
const initialUserName = Cookies.get("user_name");
// Get the query string from the current URL
const queryString = window.location.search;

// Parse the query string into an object
const queryParams = new URLSearchParams(queryString);

interface PageInfo {
  readonly sessionUuid: string;
  readonly userName?: string;
  readonly isAuthenticated: boolean;
  readonly isTotpEnabled: boolean;
  readonly isDisplayOtpEnabled: boolean;
  readonly isOtpEnabled: boolean;
  readonly isClientCertValid: boolean;
  readonly authPrompt: string;
  readonly amr: string[];
  readonly require_2fa: boolean;
  readonly queryParams: URLSearchParams;
  /**
   * If present this is a request from secondary domain.
   */
  readonly secondaryDomainRequest?: SecondaryDomainRequest;
  readonly mcsBraveheartUser: boolean;
}

interface JSParams {
  claims: string[];
  readonly next_url: string;
  readonly error_code: string;
}

/**
 * Extracts secondary domain request information from query parameters.
 */
function extractSecondaryDomainRequest(): SecondaryDomainRequest | undefined {
  const nonce = queryParams.get("secondary_nonce");
  const secondaryDomain = queryParams.get("secondary_domain");
  if (nonce && secondaryDomain) {
    return {
      nonce: nonce,
      secondaryDomain: secondaryDomain,
    };
  }
}
export const pageMetaTags: PageMetaTags = {
  my_security_key_settings_link: metaTagsDetails.my_security_key_settings_link,
  secured_by_midway_partition_name: metaTagsDetails.secured_by_midway_partition_name,
  secured_by_midway_wiki_link: metaTagsDetails.secured_by_midway_wiki_link,
  midway_partition: metaTagsDetails.midway_partition,
  compliance_validation_failed: metaTagsDetails.compliance_validation_failed,
  posture_error_message: metaTagsDetails.posture_error_message,
  device_type: metaTagsDetails.device_type,
  aea_app_redirect: metaTagsDetails.aea_app_redirect,
  posture_verification_failed: metaTagsDetails.posture_verification_failed,
};

export const pageInfo: PageInfo = {
  sessionUuid: sessionStatus.session_uuid,
  userName: initialUserName,
  isAuthenticated: sessionStatus.authenticated,
  isTotpEnabled: sessionStatus.totp_enabled,
  isDisplayOtpEnabled: sessionStatus.display_otp_enabled,
  isOtpEnabled: sessionStatus.otp_enabled,
  isClientCertValid: sessionStatus.client_cert_valid,
  authPrompt: sessionStatus.auth_prompt,
  amr: sessionStatus.amr,
  require_2fa: sessionStatus.require_2fa,
  queryParams: queryParams,
  secondaryDomainRequest: extractSecondaryDomainRequest(),
  mcsBraveheartUser: extractMcsBraveheartUserStatus(),
};

export function extractJSParams() {
  const jsParams = document.querySelector("meta[id='js-params']")?.getAttribute("content");
  return JSON.parse(jsParams ?? "{}") as JSParams;
}
